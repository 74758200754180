header {
    padding-top: 24px;
    font-size: 14px;
    line-height: 17px;
    background-color: #FFFFFF;
    padding-bottom: 18px;
    display: block;
}

.whitebg {
    background-color: #FFF!important;
    min-height: 100vh;
}

#header-section {
    justify-content: space-between;
}

/* .profile-block {
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    border-radius: 50px;
    max-height: 50px;
} */

.review-listing tr th, .review-listing tr td {
    font-size: 14px!important;
}