#volunteerLists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#volunteerLists .main-volunteer-list-container {
    padding: 1rem 0;
    width: 100%;
}

#volunteerLists .table-header > div,
.volunteer-details-contents.row > div,
.heading-container.row > div {
    flex: 1;
    width: 100%;
    /* min-width: 100px !important; */
    padding: inherit !important;
    word-wrap: break-word;
    overflow-wrap: anywhere;
}

#volunteerLists .main-volunteer-off-heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 1rem 0;
    color: #000000;
}

#volunteerLists .heading-container {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #1e1053;
    margin: 1rem 0;
    padding: 0 !important;
}

#volunteerLists .volunteer-details-contents {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0 !important;
}

#volunteerLists .volunteer-details-container {
    width: 100%;
}

#volunteerLists .select-dropdown {
    height: fit-content;
    font-size: 0.8rem;
    width: 10%;
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#volunteerLists .select-dropdown select {
    flex: 1;
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 5px;
    background: transparent;
    appearance: none;
    font-size: 0.8rem;
}

#volunteerLists .select-dropdown .dropdown-icon {
    margin-left: 5px;
    font-size: 0.8rem;
}

#volunteerLists .select-dropdown::after {
    content: "▼";
    margin-left: 5px;
    font-size: 0.8rem;
}

#volunteerLists .banner-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #da4c76;
    font-size: 0.8rem;
    cursor: pointer;
}

#volunteerLists .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}

#volunteerLists .save-button,
#volunteerLists .cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}

#volunteerLists .status-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

#volunteerLists .status-dropdownn {
    display: flex;
    /* align-items: center; */
    position: relative;
    cursor: pointer;
    width: 60px;
}

#volunteerLists .status-selected {
    padding: 5px;
    font-size: 0.8rem;
}

#volunteerLists .dropdown-icon {
    margin-left: 10px;
    font-size: 0.8rem;
}

#volunteerLists .dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 10;
}

#volunteerLists .dropdown-menu li {
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.8rem;
}

#volunteerLists .dropdown-menu li:hover {
    background-color: #f5f5f5;
}

.status-dropdownn {
    position: relative;
    cursor: pointer;
}

.status-label {
    display: inline-block;
    margin-right: 10px;
}

.dropdown-icon {
    display: inline-block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
}

.dropdown-menu li {
    padding: 10px;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}
