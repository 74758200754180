.searchbar {
    border-radius: 20px;
    border: 1px solid gray;
    padding: 4px 12px;
}

.heading {
    color: #1e1053;
    font-size: 20px;
    margin-bottom: 16px;
}

.search-date {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.back-btn {
    color: #da4c76;
    display: flex;
}

.filter-section {
    display: flex;
    gap: 10px;
    align-items: center;
}

.action-btns {
    display: flex;
    gap: 20px;
}
.action-btns button {
    background-color: #da4c76;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 4px 16px;
    border: none;
    border-radius: 25px;
}

.action-btns button:hover {
    cursor: pointer;
}

.date-filters {
    display: flex;
    gap: 20px;
    align-items: center;
}

.donation-list table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin: 20px 0;
}

.donation-list th,
.donation-list td {
    border: 1px solid #ddd;
    padding: 4px;
    text-align: left;
}

.donation-list th {
    background-color: #f4f4f4;
    font-weight: bold;
    color: #1e1053;
}

.donation-list tr:nth-child(even) {
    background-color: #f9f9f9;
}

.donation-list tr:hover {
    background-color: #ddd;
}

.bottom-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-results {
    text-align: center;
    margin: 20px 0;
}
