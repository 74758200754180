#seoFormId .seo-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}

#seoFormId .form-content label:first-child {
    font-size: 14px;
    font-weight: 600;
}

#seoFormId .buttons-section div {
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0px 0px 15px;
    padding: 5px 20px;
}

.wishwa-talks-seo .back {
    font-size: 14px;
    font-weight: 600;
    color: #da4c76;
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
}

.wishwa-talks-seo .heading {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
}
