.overlay {
	position: fixed;
	top: 0px;
	z-index: 500;
	left: 0px;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.fadeIn {
	-webkit-animation-name: fadeIn;
	animation-name: fadeIn;
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.location-select {
	width: 50vw;
	height: 50vw;
	max-height: 300px;
	max-width: 600px;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.location-select h3 {
	padding-bottom: 10px;
}

@media (min-width: 320px) and (max-width: 480px) {
	.location-select {
		width: max-content;
	}

	.location-select h3 {
		font-size: 20px;
	}

	.location-select .btn {
		font-size: 12px;
	}
}
