#needHelplists.need-help-list-main-container {
    width: 100%;
}

#needHelplists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}

#needHelplists .main-need-help-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 1rem 0;
    color: #000000;
}

#needHelplists .need-help-details-contents {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0;
    display: flex;
    flex-wrap: nowrap; /* Prevents wrapping */
    align-items: center; /* Align items vertically */
}

.row-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 20px;
    text-transform: capitalize;
    color: #1e1053;
    padding: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
}


.col-md-2, .col-md-1 {
    padding: 0 5px;
}

.col-md-2 {
    flex: 2;
}

.col-md-1 {
    flex: 1;
}

.need-help-details-contents > div {
    box-sizing: border-box;
}

.select-droopdown {
    width: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 0.7rem;
}

.banner-edit-button {
    cursor: pointer;
    margin-left: 10px;
}

.filter-dropdown {
    /* position: absolute;
    top: 100%;
    left: 0; */
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
    width: max-content;
}

.filter-dropdown div {
    padding: 8px 16px;
    font-size: 0.7rem;
    cursor: pointer;
    white-space: nowrap;
}

.filter-dropdown div:hover {
    background-color: #f2f2f2;
}

.btn-section {
    display: flex;
    gap: 20px;
    font-size: 1.2rem;
   
}
.btn-section button{
    color: white;
    background-color: #DA4C76;
    border: none;
    border-radius: 30px;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .col-md-2, .col-md-1 {
        flex: 1; 
        padding: 0;
    }

    #needHelplists .need-help-details-contents {
        flex-wrap: wrap; 
        align-items: flex-start; 
    }
}

.need-help-details-contents > div {
    box-sizing: border-box;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
}

.col-md-2, .col-md-1 {
    padding: 0 5px;
    min-width: 0; 
}
