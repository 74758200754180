.overlay {
    position: fixed;
    top: 0px;
    z-index: 999;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.popUpBox .form-text {
    text-transform: uppercase;
}

.popUpBox {
    width: 65%;
    height: 90%;
    min-height: 500px;
    max-width: 880px;
    max-height: 610px;
    background: transparent;
    border-radius: 10px;
    justify-self: center;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    border-color: transparent;
}

#signUpSelector {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
}

#signUpSelector .signinfooter {
}

.selectContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center !important;
}

.typeSelect {
    width: 200px;
    height: 250px;
    background-color: #fff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: space-around;
    padding: 10px;
    cursor: pointer;
}

.typeSelect img {
    width: 70px;
    justify-self: center;
    align-self: center;
    padding-bottom: 20px;
}

.typeSelect h4,
.typeSelect p {
    text-align: center;
    color: #444444;
}

.typeSelect p {
    font-size: 13px;
}

.popUpBox h5 {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #1e1053;
    padding-top: 15px;
    margin-bottom: 10px;
}

.orsignin {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
}

.orsignintext {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.orsigninsocial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.orsigninsocial img {
    height: 25px;
}

.signup-hint {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-align: center;
    padding-bottom: 10px;
}

.signup-hint span {
    color: #da4c76;
    font-weight: bold;
    cursor: pointer;
}

.popUpBox .form-group label {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.8);
}

.popUpBox .form-group input {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    padding: 10px 10px;
    font-size: 1rem;
}

.popUpBox .left {
    background: #1e1053;
    border-radius: 10px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: transparent;
}

.password-section {
    position: relative;
}

.password-section .toggle-button {
    font-size: 12px;
    font-weight: 600;
    color: #da4c76;
    cursor: pointer;
    position: absolute;
    top: 37px;
    right: 10px;
    background-color: white;
    padding: 5px;
}

@media (max-width: 1000px) {
    .popUpBox .left {
        display: none !important;
    }
}
@media (max-width: 600px) {
    .popUpBox .left {
        display: none !important;
    }
}

.popUpBox .right {
    flex: 2;
    background: #fff;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0px;
    border-color: transparent;
}

.popUpBox .right button {
    height: auto !important;
    font-weight: 600;
}

.signinbutton {
    background: #da4c76 !important;
    border: #da4c76 0px none !important ;
    color: #fff !important;
    border-radius: 100px !important;
    padding: 8px 20px;
    max-width: 70%;
    margin: auto;
    margin-bottom: 7px;
    cursor: pointer;
}

.otpButton {
    background: #ffffff;
    border: 1px solid #da4c76;
    border-color: #da4c76 !important;
    color: #da4c76 !important;
    box-sizing: border-box;
    border-radius: 100px !important;
    max-width: 70%;
    margin: auto;
    padding: 8px 30px;
}

.cta_buttons {
    display: flex;
    flex-direction: column;
}

.popUpBox .circle1 {
    position: absolute;
    width: 141.66px;
    height: 138.2px;
    left: 100px;
    bottom: -80px;
    background: linear-gradient(
        64.83deg,
        #f19400 14.65%,
        #e5643e 49.2%,
        #da3976 85.23%
    );
    mix-blend-mode: screen;
    opacity: 0.5;
    transform: matrix(0.98, 0.21, 0.22, -0.98, 0, 0);
    border-radius: 100px;
}

.popUpBox .circle2 {
    position: absolute;
    width: 224.13px;
    height: 218.65px;
    left: -100px;
    bottom: -100px;
    background: linear-gradient(
        109.57deg,
        rgba(241, 148, 0, 0) 12.57%,
        #f19400 33.09%,
        #e5643e 49.68%,
        #da3976 88.36%
    );
    mix-blend-mode: screen;
    opacity: 0.3;
    transform: matrix(0.98, 0.21, 0.22, -0.98, 0, 0);
    border-radius: 200px;
}

.popUpBox .circle3 {
    position: absolute;
    width: 54.82px;
    height: 53.48px;
    left: 110px;
    bottom: 70px;
    background: linear-gradient(
        90deg,
        #f19400 2.95%,
        #e5643e 49.24%,
        #da3976 97.5%
    );
    mix-blend-mode: color-dodge;
    opacity: 0.5;
    box-shadow: 0px 14px 28px rgba(0, 0, 0, 0.25);
    transform: matrix(0.98, 0.21, -0.22, 0.98, 0, 0);
    border-radius: 54px;
}

.popup-form-container {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.signinfooter {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    /* identical to box height, or 18px */
    text-align: center;
    color: #333333;
    padding-bottom: 10px;
    
}

.orsigninsocial {
    width: 70%;
    margin: auto;
}

.otp-form,
.action-container {
    justify-content: center;
}

.action-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* .action-container p {
	text-align: center;
	padding-top: 20px;
} */

/* .action-container span {
	color: #da4c76 !important;
} */

.action-container .resend-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    padding-top: 20px;
}

.action-container .resend-action {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}

.action-container .resend-otp {
    cursor: pointer;
    color: #da4c76;
    border: none;
    font-weight: normal !important;
    padding: 0px;
    padding-left: 5px;
    font-size: 14px;
    background: none;
}

.action-container .resend-otp:disabled {
    opacity: 70%;
    cursor: not-allowed;
}

.action-container .timer-container {
    width: 20px;
    height: 20px;
}

.CircularProgressbar-path {
    stroke: #da4c76;
}
.CircularProgressbar-trail {
    stroke: none;
}

.otp-title {
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20%;
}

.otp-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15%;
}

.otp-input input {
    width: 40px;
    text-align: center;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 6px;
    height: 48px;
}

@media screen and (max-width: 600px) {
    .popUpBox,
    .popup-form-container {
        width: 98%;
    }

    .selectContainer {
        flex-direction: column;
    }
    .selectContainer .typeSelect {
        margin: 5px;
    }
}
