.location-suggester {
  position: relative;
  width: 100%;
}
.location-suggester input {  
}
.location-suggester .suggestions {
  padding: 8px 10px;
  position: absolute;
  top: 100%;
  left: 10px;
  min-width: 300px;
  background: #fff;
  box-shadow: 0 0 14px rgb(0 0 0 / 8%);
  border-radius: 10px;
  z-index: 35;
}

.location-search-result {
  padding: 8px 5px;
  border-bottom: 0.5px rgb(233, 227, 227) solid;
}

.location-search-result i {
  padding-right: 10px;
}

.location-search-result:last-child {
  border-bottom: 0px none;
}

.location-search-result:hover {
  background-color: rgb(242, 235, 235);
  cursor: pointer;
  color: #da4c76;
}
