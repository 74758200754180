.popup-container{
    background-color: #FFF;
    padding:10px 20px;
    position: relative;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
    
    border-color:rgba(0, 0, 0, 0.1); 
    border-radius: 10px;
}

.popup-container .popup_header{ 
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    text-align: left; 
    color: #000000;
    margin-top: 15px; 
}
.popup-container .popup_body{
    min-height: 50px;
    /* padding-right: 20px; */
    padding-top:10px
}

.popup-container .popup_buttons{
    min-height: 50px;
    text-align: right;
    font-size: 15px;
}

.popup-container .popup_buttons.center {
    min-height: 50px;
    text-align: center;
    font-size: 15px;
}

.popup-container .popup_buttons button{
    margin-left: 10px;
}

.modal-message .layer-1 img.bg{
    width: 100%; 
}
.modal-message .layer-1 > div {
    flex:1
}
.modal-message .layer-1{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: stretch;
    position: absolute;
    top:0px;
    left:0px;
    z-index:501;
}
.modal-message .layer-2{ 
    position: absolute;
    top:0px;
    left:0px;
    z-index:502;
    width:100%;
    height: 100%;
}

.popup-container.modal-message{
    padding:0px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    position: relative;
    width:60vw;
    height:500px;
    max-width: 600px;
} 

.popup-container .custom-popup{
    display:flex; 
    flex-direction:column;
    justify-content:space-evenly;
    align-self:stretch;
    position:absolute;
    height:100%;
    width:100%;
    align-items:space-between
}

.popup-container .custom-popup i{
    font-size:28px;
    color:#E5643E
}
.popup-container .custom-popup h3{
    font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 150%;
/* or 42px */

text-align: center;

color: #1E1053;
}
.popup-container .custom-popup h4{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%; 
    text-align: center; 
    color: #E5643E;
}
.popup-container .custom-popup h5{
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px; 
    text-align: center; 
    color: rgba(0, 0, 0, 0.8);
}


@media only screen and (max-width: 600px) {
    .popup-container.modal-message{
        width:90vw;
        height:400px;
        max-width: 90vw;
    } 
}