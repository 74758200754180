#dropOffSetup .main-drop-off-container {
    padding: 1rem 0px;
    width: 100%;
}
#dropOffSetup .mian-drop-off-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 1rem;
    color: #000000;
}
#dropOffSetup label {
    font-size: 0.9rem;
}
#dropOffSetup .no-margin {
    margin-top: 10px !important;
}
#dropOffSetup .drop-area {
    margin: 1rem 0px;
}
#dropOffSetup .short-bio {
    margin: 1rem 0px;
    padding: 0px;
}
#dropOffSetup .shor-bio-area .form-group {
    padding: 0px !important;
    margin: 0px;
}
#dropOffSetup .short-bio-container {
    margin: 0.5rem 1rem;
    width: 100%;
}
#dropOffSetup .short-bio-heading-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#dropOffSetup .required-star {
    color: red;
    padding-left: 5px;
    vertical-align: middle;
    font-size: 12px;
    align-self: flex-start;
}
#dropOffSetup .donate-materials-map-container {
    width: 100%;
    margin: 2.5rem 0px;
}
#dropOffSetup .select-map {
    width: 40%;
}
#dropOffSetup .highlighted-red-color {
    color: #da4c76cc;
}
#dropOffSetup .donate-materials-map-text {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.2rem;
    color: rgba(0, 0, 0, 0.8);
}
#dropOffSetup .select-map {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    height: 20rem;
    width: 40%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #555555;
    font-weight: 300;
    margin-bottom: 2.5rem !important;
}
#dropOffSetup .select-locations-options-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#dropOffSetup .short-bio-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 150%;
    display: flex;
    align-items: flex-start;
    color: #000000;
}
#dropOffSetup .map-dashBox {
    height: 10rem;
    width: 20rem;
    border: 1px solid #999999;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(199, 199, 199);
    cursor: pointer;
    padding: 20px 40px;
}
#dropOffSetup .short-bio-maximum-characters {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.4rem;
    text-align: right;
    align-self: flex-end;
    color: #999999;
}
#dropOffSetup .volunteer-bio {
    width: 100%;
    height: 10rem;
    border: 1px solid #999999;
    margin-bottom: 1rem;
}
#dropOffSetup .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 10rem;
    margin-left: 2rem;
}
#dropOffSetup .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}
