/* Toast Mods */
.Toastify__toast-container {
}
.Toastify__toast.Toastify__toast--success {
	color: #fff;
	border-top: #07bc0c 5px solid;
	background: linear-gradient(90deg, #f19400 2.95%, #e5643e 49.24%, #da3976 97.5%);
}
/* Toast Mods */

header {
	padding-top: 2px;
	font-size: 14px;
	line-height: 17px;
	background-color: #ffffff;
	padding-bottom: 8px;
}

.header-bar > div {
	flex: 1;
	padding: 0px 5px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.header-bar > div:first-child {
	padding-left: 0px;
	max-width: 250px;
}

.header-bar > div:nth-child(2) {
	max-width: 200px;
}

.header-bar > div:last-child {
	padding-right: 0px;
	flex: 0.7;
	max-width: fit-content;
	justify-content: flex-end;
}

.header-bar > div {
	flex: 1;
}

.header-bar > div:nth-last-child(2) {
	flex: none;
	justify-content: flex-start;
}

.header-bar > div:nth-last-child(3) {
	justify-content: flex-end;
}

header .container {
	padding-right: 0px;
}

.row {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

#backend-header {
}

.border-bot {
	height: 6px;
	background: linear-gradient(90deg, #f19400 2.95%, #e5643e 49.24%, #da3976 97.5%);
	position: absolute;
	bottom: 0px;
	left: 0px;
	z-index: 1000;
	width: 100%;
}

.profile-block {
	position: relative;
	padding: 2px 10px;
	box-shadow: 0px 0px 24px rgb(0 0 0 / 10%);
	background: linear-gradient(90deg, #f19400 2.95%, #e5643e 49.24%, #da3976 97.5%);
	border-radius: 5px;
	font-size: 13px;
	border-radius: 50px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	min-width: 150px;
	cursor: pointer;
}

.profile-block i {
	padding-left: 10px;
}

.profile-block img {
	width: 30px;
	height: 30px;
	border-radius: 30px;
	margin-right: 10px;
	border: #555 1px solid;
}

.profile-block img {
	border-color: #fff !important;
	border: 5px solid;
}

#backend-header #logo {
	max-width: 250px;
}

#backend-header #header-section {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

#backend-header .profile-block {
	max-height: 34px;
	display: flex;
}

.user-dropdown {
	position: absolute;
	top: calc(100%);
	left: calc(0%);
	z-index: 200;
	padding: 10px;
	width: calc(100%);
	background-color: #fff;
}

.user-dropdown ul {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

.user-dropdown ul li {
	padding: 5px 10px;
}

.loc-selector i {
	padding-top: 1px;
}

.loc-selector {
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	cursor: pointer;
}

#search {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#search .input-group-append {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 0px !important;
	z-index: 20;
	position: absolute;
	right: 10px;
	top: 4px;
}

#search .input-group {
	justify-content: center;
	margin-bottom: 0px !important;
}

#search input {
	padding: 5px;
	font-size: 12px;
	border-radius: 17px;
	line-height: 20px;
	padding: 10px;
	height: 34px;
}

#topbarbox {
	width: 100%;
	height: 34px;
	padding: 5px 12px;
	background: #ffffff;
	border: 0.5px solid #da4c76;
	border-radius: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	margin-right: 5px;
	width: fit-content;
}

#topbarbox #raise_funds {
	font-weight: bold !important;
	text-align: center;
	letter-spacing: 0.08em;
	/* text-transform: uppercase; */
	color: #da4c76;
	/* padding-right: 10pt; */
}

#topbarbox #raise_funds_menu {
	letter-spacing: 0.06em;
	/* color: #da4c76; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	cursor: pointer;
	padding-left: 5px;
	font-weight: bold !important;
}

#topbarbox .line {
	border-right: 2px solid rgba(0, 0, 0, 0.08);
	height: 15px;
	margin: 0px 10px;
}

#login_anchor {
	font-style: normal;
	font-weight: bold;
	text-align: right;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: rgba(0, 0, 0, 0.8);
	font-size: 11px;
	padding-top: 5px;
	cursor: pointer;
	margin: 0px;
	justify-self: center;
	align-self: center;
}

.fa-search {
	align-self: flex-end;
}

#main-navigation {
	padding-top: 15px;
	padding-right: 0px !important;
}

.nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.nav a {
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	color: #050505;
}

.nav a.active {
	color: #da4c76;
}

.bottom-rounded {
	border-bottom-left-radius: 70px !important;
	border-bottom-right-radius: 70px !important;
}

.top-rounded {
	border-top-left-radius: 70px !important;
	border-top-right-radius: 70px !important;
}

#logo {
	padding-left: 10px;
	/* border-right: #CCC 1px solid; */
	max-width: 300px;
}

.mobile #logo {
	padding-left: 10px;
	/* border-right: #CCC 1px solid; */
}

.mobile .fa-bars {
	font-size: 25px;
}

.mobile #logo .img-fluid {
	padding-left: 10px;
	max-width: 200px;
}

.mobile .mobileMenu {
	position: fixed;
	top: 0px;
	right: 0px;
	background-color: #fff !important;
	width: 80%;
	height: 100vh;
	z-index: 55;
	opacity: 0.95;
	border-left: #eff 1px solid;
}

.mobile .mobileMenu {
	padding: 20px 20px;
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.mobile .mobileMenu .closeButton {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 25px;
	padding: 10px;
}

.mobile .mobileMenu .menu-small-logo {
	width: 60%;
}

.mobile .mobileMenu .nav {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
}

.mobile .mobileMenu .nav a {
	display: block;
	text-align: left;
	font-size: 14px;
	padding-top: 30px;
}

.mobile .mobileMenu #topbarbox {
	margin-top: 30px;
}

.mobile .mobileMenu .signin-mobile-button {
	padding-left: 16px;
	font-size: 14px;
	padding-top: 30px;
	display: flex;
	font-weight: bold;
	font-size: 16px;
}

.orgMessage {
	position: fixed;
	top: 100px;
	left: 0px;
	width: 100%;
	background-color: #1e1053;
	color: #fff;
	text-align: center;
	padding: 30px 10px;
	z-index: 999;
	font-size: 20px;
}

.orgMessage i {
	color: #e5643e;
	align-self: center;
	justify-self: center;
}

.causesMenu {
	position: absolute;
	top:30px;
	left: 0px;
	z-index: 90;
	background-color: #ffff;
	min-width: 25vw;
	min-height: 100px;
	height: auto;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.mobile .causesMenu {
	width: 100%;
	min-height: 100%;
}

.causesMenu ul {
	list-style: none;
	margin-left: 0px;
	padding-left: 0px;
}

.causesMenu ul li {
	padding: 2px 4px;
	color: #333333;
	margin-left: 0px;
	padding-left: 0px;
	/* cursor: pointer; */
}

.causesMenu ul li a{
	text-align: left !important;
}

.causesMenu ul img {
	padding-right: 10px;
	width: 30px;
}

.causesMenu ul li a,
.causesMenu ul li a:hover {
	color: #333333;
}

#main-navigation .nav {
	justify-content: flex-end;
}

.disabled {
	pointer-events: none;
	color: grey; 
	background-color: #f0f0f0; 
	cursor:not-allowed;
	/* text-decoration: line-through;  */
	opacity: 0.6; 
	border: 1px solid #ccc;
	padding: 5px 10px;
	border-radius: 5px; 
	display: inline-block; 
	margin-right: 10px;
  }
  

@media screen and (min-width: 641px) {
	header #main-navigation .nav > a:first-child {
		text-align: left !important;
		padding-left: 0px !important;
	}
	header #main-navigation .nav > a:last-child {
		text-align: right !important;
		padding-right: 0px !important;
	}
}

@media screen and (max-width: 641px) {
	.causesMenu {
		width: 100%;
		min-height: 100%;
	}
	.wishwa-logo {
		padding: 10px 8%;
	}
	.hero .hero_head1 {
		font-size: 30px !important;
	}
	.hero .hero_head2 {
		font-size: 40px !important;
	}
	.hero .container {
		display: flex !important;
		flex-direction: column !important;
		justify-content: space-around !important;
	}
	.hero .container div:nth-child(3) {
		width: 95% !important;
	}
}

@media (min-width: 320px) {
	/* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
	/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
	/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (min-width: 961px) {
	/* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
	/* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
	/* hi-res laptops and desktops */
}

@media (min-width: 481px) {
	.tablet .causesMenu {
		top: 10%;
		width: 90%;
		font-size: 180%;
		line-height: 200%;
	}

	.tablet .mobileMenu .nav a {
		display: block;
		text-align: left;
		font-size: 14px;
		padding-top: 30;
		font-size: 180%;
		line-height: 200%;
	}

	.tablet .mobileMenu .signin-mobile-button {
		font-size: 180%;
		line-height: 200%;
	}

	.tablet #topbarbox {
		font-size: 180%;
		line-height: 200%;
		padding: 5% 10%;
	}

	.tablet .mobileMenu .menu-small-logo {
		width: 60%;
		max-width: none !important;
	}
}
