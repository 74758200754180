/* Start of Hr Block style */

#hrBlockId {
    margin-bottom: 30px;
}
#hrBlockId .block-title {
    font-size: 24px;
    font-weight: 600;
    color: #1e1053;
    box-shadow: 0px 5px 20px rgba(30, 16, 83, 0.1);
    padding: 15px;
    border-radius: 15px;
}

#hrBlockId .back {
    font-size: 14px;
    font-weight: 600;
    color: #da4d73;
    margin: 20px 0px;
    cursor: pointer;
    width: fit-content;
}

/* End of Hr Block style */

/* Start of Create job style */

#createJobId .form-content label {
    font-size: 14px;
    font-weight: 600;
}

#createJobId .form-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    margin-left: 12px;
}

#createJobId .file-name {
    font-size: 14px;
    font-weight: 600;
}

#createJobId .file-name i {
    color: #da4c76;
    margin-left: 5px;
    cursor: pointer;
}

#createJobId .note {
    font-size: 12px;
    font-weight: 600;
    color: #999999;
    margin-left: 15px;
    margin-bottom: 15px;
}

#createJobId .buttons-section {
    margin: 30px 0px;
}

#createJobId .buttons-section .btn {
    margin-right: 25px;
}

/* End of Create job style */

/* Start of Jobs list style */

#jobsListId {
    display: flex;
    flex-direction: column;
    min-height: 525px;
}

#jobsListId .count {
    font-size: 14px;
    align-self: flex-end;
    margin-right: 100px;
    margin-bottom: 15px;
}

#jobsListId .heading-section {
    font-size: 14px;
    font-weight: 600;
    margin: 15px 0px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
}

#jobsListId table {
    width: 100%;
}

#jobsListId table tr {
    display: flex;
    margin: 15px 0px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
}

#jobsListId table th {
    font-size: 12px;
    padding: 0px 5px;
    text-align: center;
    flex: 1;
}

#jobsListId .details-section {
    font-size: 14px;
    margin-bottom: 20px;
    display: flex;
}

#jobsListId .details-section td {
    flex: 1;
    font-size: 12px;
    padding: 0px 5px;
    text-align: center;
}

#jobsListId .details-section .status-dropdown {
    border: none;
    font-size: 14px;
}

#jobsListId .details-section .status-dropdown option {
    color: #da4c76;
}

#jobsListId .job-details {
    font-size: 14px;
    border-bottom: 1px solid #eeeeee;
}

#jobsListId .job-details > div {
    margin-bottom: 15px;
}

#jobsListId .job-details .details-heading {
    font-weight: 600;
}

#jobsListId .download {
    width: fit-content;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 15px;
    margin-right: 15px;
}

/* End of Jobs list style */

/* Start of Candidate Details style */

#candidateDetailsId .student-details {
    font-size: 14px;
    margin-bottom: 25px;
}

#candidateDetailsId .student-details > div {
    margin-bottom: 15px;
}

#candidateDetailsId .student-details .details-heading {
    font-weight: 600;
}

#candidateDetailsId .sub-heading {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0px;
}

#candidateDetailsId .details {
    font-size: 14px;
    margin-bottom: 15px;
}

#candidateDetailsId .link {
    font-size: 12px;
    color: #4867aa;
    font-weight: 600;
    cursor: pointer;
}

#candidateDetailsId .download {
    font-size: 14px;
    margin-top: 40px;
}

/* Start of Candidate Details style */
