.form-container{
    background-color: white;
}
.image-upload-container {
    margin-left: 15px;
    position: relative;
}

.uploaded-image {
    width: 150px;
    height: 150px;
    border-radius: 5px;
}

.close-icon {
    position: absolute;
    /* top: -10px; */
    right: 1px;
    cursor: pointer;
    font-size: 10px;
    background-color: white;
    border-radius: 50%;
    color: #DA4C76;
    padding: 5px;
   
}

