.alert-layer {
	position: fixed;
	top: 0px;
	z-index: 500;
	left: 0px;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.alert-container {
	font-size: 16px;
	color: white;
	max-width: 60vw;
	border-radius: 10px;
	padding: 20px 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
}

.alert-container .icon {
	font-size: 22px;
	margin-right: 10px;
}

.alert-container .description {
	margin-top: 5px;
	font-size: 12px;
}

.alert-container .close-icon {
	font-size: 24px;
	position: absolute;
	top: -10px;
	right: -10px;
	background-color: white;
	border-radius: 50%;
	cursor: pointer;
}

@media (min-width: 320px) and (max-width: 480px) {
	.alert-container {
		font-size: 14px;
		flex-direction: column;
		text-align: center;
	}

	.alert-container .icon {
		margin-right: 0px;
		margin-bottom: 5px;
	}
}
