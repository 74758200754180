#needHelplists.need-help-list-main-container {
    width: 100%;
    padding: 20px;
}

#needHelplists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

#needHelplists .main-need-help-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin: 1rem 0;
    color: #333333;
}

#needHelplists .need-help-details-contents {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
    margin-bottom: 1rem;
    padding: 10px;
    display: flex;
    flex-wrap: nowrap; /* Prevents wrapping */
    align-items: center; /* Align items vertically */
    border-bottom: 1px solid #e0e0e0;
    width: 100%; /* Ensure the item spans the full width */
}

.row-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 24px;
    text-transform: capitalize;
    color: #1e1053;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 4px;
    width: 100%; /* Ensure the heading spans the full width */
}

.col-md-2,
.col-md-1 {
    padding: 0 10px;
    flex: 1; /* Make each column take up equal space */
}

.col-md-2 {
    flex: 2;
}

.col-md-1 {
    flex: 1;
}

.need-help-details-contents > div {
    box-sizing: border-box;
    width: 100%; /* Ensure each div spans the full width */
}

.select-dropdown {
    width: 100%;
    border: 1px solid #ddd;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 5px;
    border-radius: 4px;
}

.banner-edit-button {
    cursor: pointer;
    margin-left: 10px;
    color: #da4c76;
}

.filter-dropdown {
    /* position: absolute;
    top: 100%;
    left: 0; */
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 10;
    width: max-content;
    border-radius: 4px;
}

.filter-dropdown div {
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    white-space: nowrap;
}

.filter-dropdown div:hover {
    background-color: #f2f2f2;
}

.btn-section {
    display: flex;
    gap: 20px;
    font-size: 1.2rem;
    margin-top: 20px;
}

.btn-section button {
    color: white;
    background-color: #da4c76;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .col-md-2,
    .col-md-1 {
        flex: 1;
        padding: 0;
    }

    #needHelplists .need-help-details-contents {
        flex-wrap: wrap;
        align-items: flex-start;
    }
}

.need-help-details-contents > div {
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.col-md-2,
.col-md-1 {
    padding: 0 10px;
    min-width: 0;
}

.need-list-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
}
