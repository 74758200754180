.banner-upload .heading {
    font-size: 26px;
    font-weight: 600;
    color: #1e1053;
    margin-bottom: 15px;
    margin-left: 15px;
}

.banner-upload .form-content .label-group label:first-child {
    font-size: 14px;
    font-weight: 600;
}

.banner-upload .form-content label:first-child {
    font-size: 14px;
    font-weight: 600;
}

.banner-upload .buttons-block div {
    font-size: 14px;
    font-weight: 600;
    padding: 8px 20px;
    margin: 15px 20px 15px 0px;
}

.banner-upload .cta-section {
    padding: 0px;
    flex: 1;
}

.banner-upload .disable {
    opacity: 0.5;
    pointer-events: none;
}

.cta-section .cta-link-input {
    flex: 1;
    margin: 0px 10px 0px 5px;
    position: relative;
}

.cta-section .cta-link-input i {
    font-size: 12px;
    position: absolute;
    top: 7px;
    left: 110px;
    cursor: pointer;
}

.info-list li {
    margin-bottom: 10px;
}

/* Start of Banner List style */

#bannersListId .list-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

#bannersListId .heading-button-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 10px;
}

#bannersListId .heading-button-section .add-button {
    height: fit-content;
    font-weight: 600;
    border: 2px solid black;
    padding: 3px 5px;
    border-radius: 15px;
    text-transform: uppercase;
    cursor: pointer;
}

#bannersListId .list-heading {
    font-size: 14px;
    font-weight: 700;
    color: #1e1053;
    text-transform: uppercase;
    margin-bottom: 15px;
}

#bannersListId .list-heading .status-filter {
    border: none;
    background-color: white;
    font-size: 14px;
    font-weight: 700;
    color: #1e1053;
    text-transform: uppercase;
    cursor: pointer;
}

#bannersListId .list-heading .status-filter option {
    font-size: 12px;
    text-transform: none;
}

#bannersListId .list-details {
    font-size: 12px;
    margin-bottom: 15px;
    padding: 5px;
    border-radius: 10px;
}

#bannersListId .list-details .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: lightyellow;
    border-radius: 15px;
    overflow: hidden;
}

#bannersListId .list-details .image img {
    max-width: 100%;
    height: 150px;
}

#bannersListId .status-dropdown {
    border: none;
    font-size: 14px;
    cursor: pointer;
}

#bannersListId .status-dropdown option {
    color: #da4c76;
}

#bannersListId .list-details .update-button {
    width: fit-content;
    font-weight: 600;
    color: white;
    background-color: #da4c76;
    padding: 0px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.seo-form {
    min-width: 500px;
}

.seo-form .buttons-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.seo-form .buttons-section div {
    margin-right: 15px;
}

/* End of Banner List style */
