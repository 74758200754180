#partnersLists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#partnersLists .main-partner-list-container {
    padding: 1rem 0px !important;
    width: 100%;
}
#partnersLists .mian-partner-lists-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    margin: 1rem 0px;
    color: #000000;
}
#partnersLists .heading-container {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #1e1053;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 0px !important;
}
#partnersLists .partners-details-contents {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0px !important;
}

#partnersLists .select-dropdown {
    height: fit-content;
    font-size: 0.7rem;
    width: fit-content;
    border: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    padding-left: 0rem !important;
    cursor: pointer;
}
#partnersLists .staus {
    margin-right: 0.2rem !important;
}
.padding-no {
    padding: opx !important;
    padding-right: 5px !important;
}
#partnersLists .banner-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #da4c76;
    font-size: 0.8rem;
    cursor: pointer;
}
#partnersLists .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}
#partnersLists .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}

.main-partner-list-container {
    padding: 20px;
}

.main-partner-lists-heading {
    font-size: 24px;
    margin-bottom: 10px;
}

.heading-container {
    display: grid;
    grid-template-columns: repeat(8, minmax(100px, 1fr));
    font-weight: bold;
    margin-bottom: 10px;
}

.grid-header {
    padding: 5px;
}

.partners-details-contents {
    display: grid;
    grid-template-columns: repeat(8, minmax(100px, 1fr));
    align-items: center;
}

.grid-item {
    padding: 5px;
}

.emailItem {
    grid-column: span 2; /* Make email span 2 columns */
}

.select-dropdown {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
}

.banner-edit-button {
    text-align: center;
}

.banner-edit-button i {
    cursor: pointer;
}

.no-data {
    width: max-content;
    height: 50px;
    text-align: center;
    margin: auto;
}
