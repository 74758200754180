#materailsRequestsLists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#materailsRequestsLists .materails-requests-main-container {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}


#materailsRequestsLists .heading-container {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #1e1053;
    margin: 1rem 0;
    padding: 0;
}

.padding-no {
    padding: 0 !important;
}


#materailsRequestsLists .col-md-2 a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


#materailsRequestsLists .organistaion-name {
    text-align: left !important;
}

.materials-requests-lists-container {
    width: 100%;
}


#materailsRequestsLists .materials-requests-lists-contents {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0 !important;
}


#materailsRequestsLists .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}


#materailsRequestsLists .select-dropdown {
    height: fit-content;
    font-size: 0.7rem;
    width: fit-content;
    border: none;
    background-color: transparent;
    /* margin: 0; */
    margin-left: -10px;
    padding: 0;
    padding-left: 0 !important;
    cursor: pointer;
}

#materailsRequestsLists .banner-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #da4c76;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
}


#materailsRequestsLists .materials-requests-lists-contents {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0 !important;
}

#materailsRequestsLists .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}


.materials-requests-lists-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.status-container {
    display: flex;
    align-items: center;
    margin-left: 0px;
    font-family: "Montserrat";
    font-weight: bold;
}

.status-label {
    /* margin-right: 10px;  */
}

.status-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;
}

.dropdown-icon {
    margin-left: 5px; 
    transition: transform 0.3s ease;
}

.dropdown-icon.rotate {
    transform: rotate(180deg);
}

.status-options {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    padding: 5px 0;
    margin: 0;
    z-index: 1000;
    width: 150px;
    top: 100%; 
    left: 0;
}

.status-options li {
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.status-options li:hover {
    background-color: #f1f1f1;
}
.horizontal-scroll {
    overflow-x: auto;
    white-space: nowrap;
}

.materials-requests-lists-contents {
    display: inline-block;
    vertical-align: top;
}
