.wrapper_jumper {
	 position: absolute;
	 width: 252px;
	 height: 36px;
	 left: 50%;
	 top: 50%;
	 transform: translate(-50%,-50%);
}

 .block {
	 position: absolute;
	 width: 36px;
	 height: 36px;
	 border-radius: 40px;
	 box-shadow: 0 0 10px #A4F9FC;
}
 .block:nth-child(1) {
	 left: 0;
	 background-color: #FEC51D;
}
 .block:nth-child(2) {
	 left: 54px;
	 background-color: #F19400;
}
 .block:nth-child(3) {
	 left: 108px;
	 background-color: #E5643E;
}
 .block:nth-child(4) {
	 left: 162px;
	 background-color: #F05DA4;
}
 .block:nth-child(5) {
	 left: 216px;
	 background-color: #DA3976;
}
 @keyframes slide {
	 0%, 8% {
		 transform: translateX(0);
		 background-color: #FEC51D;
	}
	 92%, 100% {
		 transform: translateX(216px);
		 background-color: #DA3976;
	}
}
 .mover {
	 animation: slide 1.3s infinite alternate cubic-bezier(0.645,0.085,0.455,1);
}
 @keyframes jump1 {
	 0% {
		 top: 0;
		 transform: rotate(0);
	}
	 7.5% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 15% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 19% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(-180deg);
	}
	 23%, 70% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 77.5% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 85% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
	 89% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(0);
	}
	 94%, 100% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
}
 @keyframes jump2 {
	 0% {
		 top: 0;
		 transform: rotate(0);
	}
	 7.5% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 15% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 19% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(-180deg);
	}
	 23%, 57.5% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 65% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 72.5% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
	 76.5% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(0);
	}
	 80.5%, 100% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
}
 @keyframes jump3 {
	 0% {
		 top: 0;
		 transform: rotate(0);
	}
	 7.5% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 15% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 19% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(-180deg);
	}
	 23%, 45% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 52.5% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 60% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
	 64% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(0);
	}
	 68%, 100% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
}
 @keyframes jump4 {
	 0% {
		 top: 0;
		 transform: rotate(0);
	}
	 7.5% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 15% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 19% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(-180deg);
	}
	 23%, 32.5% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(-180deg);
	}
	 40% {
		 top: -36px;
		 transform: rotateZ(-90deg);
	}
	 47.5% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
	 51.5% {
		 top: 24px;
		 height: 24px;
		 transform: rotateZ(0);
	}
	 55.5%, 100% {
		 top: 0;
		 height: 36px;
		 transform: rotateZ(0);
	}
}
 .jumper {
	 transform-origin: -9px;
}
 .jumper:nth-child(2) {
	 animation: jump1 2.6s .2s infinite linear;
}
 .jumper:nth-child(3) {
	 animation: jump2 2.6s .35s infinite linear;
}
 .jumper:nth-child(4) {
	 animation: jump3 2.6s .5s infinite linear;
}
 .jumper:nth-child(5) {
	 animation: jump4 2.6s .65s infinite linear;
}
 

body {
	margin: 0;
  }
  .loader {
	position: absolute;
	top: 50%;
	left: 40%;
	margin-left: 10%;
	transform: translate3d(-50%, -50%, 0);
  }
  .dot {
	width: 24px;
	height: 24px;
	background: #3ac;
	border-radius: 100%;
	display: inline-block;
	animation: slide 1s infinite;
  }
  .dot:nth-child(1) {
	animation-delay: 0.1s;
	background: #f19400;
  }
  .dot:nth-child(2) {
	animation-delay: 0.2s;
	background: #e5643e;
  }
  .dot:nth-child(3) {
	animation-delay: 0.3s;
	background: #da3976;
  }
  @-moz-keyframes slide {
	0% {
	  transform: scale(1);
	}
	50% {
	  opacity: 0.3;
	  transform: scale(2);
	}
	100% {
	  transform: scale(1);
	}
  }
  @-webkit-keyframes slide {
	0% {
	  transform: scale(1);
	}
	50% {
	  opacity: 0.3;
	  transform: scale(2);
	}
	100% {
	  transform: scale(1);
	}
  }
  @-o-keyframes slide {
	0% {
	  transform: scale(1);
	}
	50% {
	  opacity: 0.3;
	  transform: scale(2);
	}
	100% {
	  transform: scale(1);
	}
  }
  @keyframes slide {
	0% {
	  transform: scale(1);
	}
	50% {
	  opacity: 0.3;
	  transform: scale(2);
	}
	100% {
	  transform: scale(1);
	}
  }
  