#pickUpRequests.main-container {
    width: 100%;
}
#pickUpRequests .mian-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    margin: 1rem 0px 1.5rem 0px;
    color: #1e1053;
    text-transform: uppercase;
}
#pickUpRequests .details-container {
    width: 100%;
    display: flex;
}
#pickUpRequests .details-text {
    width: 25%;
    color: #1e1053;
    font-size: 1rem;
}
#pickUpRequests .margin-bottom {
    margin-bottom: 1rem !important;
}
#pickUpRequests .details-Name {
    width: 40%;
    display: flex;
    flex-direction: column;
}
#pickUpRequests .details-name-container {
    display: flex;
    font-size: 1rem;
}
#pickUpRequests .details-name-container-material-name {
    width: 6rem;
}
#pickUpRequests .details-name-container-material-counts {
    font-weight: 600;
    width: 2rem;
    margin-right: 1rem;
}
#pickUpRequests .banner-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #da4c76;
    font-size: 1rem;
    cursor: pointer;
}
#pickUpRequests .donar-images-container {
    margin-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
}
#pickUpRequests .align-center {
    align-items: center !important;
    display: flex;
    height: fit-content !important;
}
#pickUpRequests .donar-images {
    width: 200px;
    height: 150px;
    margin-right: 3rem;
    border-radius: 1rem;
    margin-top: 0.8rem;
}
#pickUpRequests .donar-images-map {
    height: 100%;
    margin-right: 3rem;
    border-radius: 1rem;
    margin-top: 0.8rem;
}
#pickUpRequests .donar-selection {
    width: 15rem;
    border-radius: 10px;
    padding: 4px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
}
#pickUpRequests .donar-selection-status {
    width: 15rem;
    border-radius: 10px;
    padding: 4px;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: #999999;
}
#pickUpRequests .no-padding {
    padding: 0px !important ;
    margin-top: 2rem;
}
#pickUpRequests .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}
#pickUpRequests .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}
#pickUpRequests .Updated-container {
    width: 100%;
}
#pickUpRequests .padding {
    padding: 0px !important;
}
#pickUpRequests .lists-text {
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 120%;

    color: #555555;
}
.remove {
    position: absolute;
    padding: 2px 4px;
    font-size: 1rem;
    color: #da4c76;
    border-radius: 50%;
    background-color: #ffffff;
    right: -50%;
    top: 10%;
    cursor: pointer;
}
.image-item {
    width: 15%;
    height: 150px;
    margin-right: 1rem;
    position: relative;
    margin-bottom: 2rem;
}
#pickUpRequests .lists-text-heading {
    color: #1e1053;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 120%;
}
.select-component {
    margin-right: 10px;
}
.capitalise {
    text-transform: uppercase !important;
}
