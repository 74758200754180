.pagination-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.pagination-btn {
    background-color: #D9D9D9;
    border: 1px solid #D9D9D9;
    color: black;
    border-radius: 25px;
    height: 30px;
    aspect-ratio: 1/1;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.prev {
    background-color: white;
    color: #da4c76; 
}
.next{
    background-color: white;
    color: #da4c76; 
}
.notactive{
    height: 16px;
    width: 16px;
    font-size: 10px;
    text-align: center;
}
.pagination-btn:hover {
    /* background-color: #da4c76;
    color: white; */
    color: #da4c76;
}

.pagination-btn:disabled {
    /* background-color: #f1f1f1; */
    color: #da4c76;
    cursor: not-allowed;
}

.pagination-btn.active {
    background-color: #da4c76;
    color: black;
    height: 20px;
    width: 20px;
    font-size: 14px;
}

.items-per-page-select {
    width: auto;
    max-width: 150px;
    padding: 0.25rem 0.5rem;
    border-color: #da4c76;
    color: #da4c76;
    border-radius: 4px;
}

.items-per-page-select:focus {
    border-color: #da4c76;
    box-shadow: 0 0 0 0.2rem rgba(218, 76, 118, 0.25);
}

.prev,
.next {
    border: none;
    font-size: 24px;
    font-weight: 500;
}
