#dropOfLocationLists .main-drop-off-container {
    padding: 1rem 0px;
    width: 100%;
}
#dropOfLocationLists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#dropOfLocationLists .mian-drop-off-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    margin: 1rem 0px;
    color: #000000;
}

#dropOfLocationLists .heading-container {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #1e1053;
    margin-bottom: 1rem;
    padding: 0px !important;
}
#dropOfLocationLists .drop-off-details-contents {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0px !important;
}
#dropOfLocationLists .drop-off-details-container {
    width: 100%;
}
#dropOfLocationLists .select-dropdown {
    height: fit-content;
    font-size: 0.8rem;
    width: fit-content;
    border: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    padding-left: 0rem !important;
    cursor: pointer;
}
#dropOfLocationLists .banner-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #da4c76;
    font-size: 0.8rem;
    cursor: pointer;
}
#dropOfLocationLists .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}
#dropOfLocationLists .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}
