.title-row {
    font-size: 16px;
    font-weight: bold;
}

.pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 12px;
    padding-top: 15px;
    margin-bottom: 15px;
}

.pagination div {
    padding: 5px;
}

.pagination i:last-child {
    margin-left: 10px;
}

.pagination i:first-child {
    margin-right: 10px;
}

.pagination div {
    color: #da4c76;
}

.pagination i {
    color: #000;
    cursor: pointer;
}

.pagination i.inactive {
    color: rgba(30, 16, 83, 0.2);
    cursor: auto;
}

.wave_top_page {
    background-position: center -150px;
    background-repeat: no-repeat;
    background-color: #fff;
    padding-bottom: 30px;
}

.dashboard .container {
    min-height: inherit;
    padding-top: 40px;
    /* margin: 0px 20px; */
    min-width: fit-content;
}

.dashboard-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-content: stretch;
    min-height: inherit;
}

.dashboard-menu {
    background: #1e1053;
    box-shadow: 0px 10px 20px rgba(30, 16, 83, 0.1);
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    flex: 3;
    padding: 20px;
    padding-right: 0px;
}

.dashboard-content {
    background-color: #fff;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    flex: 12;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
    padding: 20px;
}

.dash-menu-container > ul {
    list-style: none;
    margin: 0px;
    padding-left: 10px;
}

.dash-menu-container ul li {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding-left: 20px;
}

.dash-menu-container ul li a,
.dash-menu-container ul li a:focus,
.dash-menu-container ul li a:hover {
    color: #ffffff;
}

.dash-menu-container > ul li {
    padding-top: 20px;
    padding-bottom: 10px;
    cursor: pointer;
}

.dash-menu-container > ul ul {
    margin: 0px;
    margin-top: 10px;
    list-style: none;
    padding-inline-start: 0px;
}

.dash-menu-container > ul ul li {
    padding: 15px;
    font-weight: 400;
    cursor: pointer;
}

.dash-menu-container > ul li.active {
    background: linear-gradient(
        90deg,
        #f19400 2.95%,
        #e5643e 49.24%,
        #da3976 97.5%
    );
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding-bottom: 20px;
}

.dash-menu-container > ul ul li.active {
    background: linear-gradient(
        90deg,
        #f19400 2.95%,
        #e5643e 49.24%,
        #da3976 97.5%
    );
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding-bottom: 15px;
}

.dash-profile {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}

.dash-profile .img {
    flex: 1;
}

.dash-profile .img img {
    width: calc(100%);
    border-radius: 50%;
    border: #fff 3px solid;
}

.dash-profile .name {
    flex: 4;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    padding: 10px 10px;
}

.dash-box {
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.dashboard-content .box {
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    min-height: 100px;
    padding: 17px 26px;
    margin-bottom: 10px;
}

.dashboard-content .box h6 {
    font-size: 16px;
    margin-bottom: 23px;
}

.campaign-list .campaign {
    padding-bottom: 20px;
    padding-top: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.campaign-list .campaign:last-child {
    border-bottom: none;
}

.campaign-list table thead td {
    padding-bottom: 20px;
    font-size: 14px;
}

.campaign-list table td {
    padding: 5px 5px;
}

.fundraiser-cta {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px 10px;
}

.fundraiser-cta .right div {
    background: #1e1053;
    border-radius: 30px;
    color: #fff;
    padding: 8px 40px;
    align-self: center;
    cursor: pointer;
}

.fundraiser-cta .left {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    align-self: center;
    color: #000000;
}

.radio label:first-child {
    margin-right: 10px;
}

.thirty-days-stats {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.thirty-days-stats .title {
    font-size: 14px;
    font-weight: bold;
}

.thirty-days-stats .value {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* margin-top: 10px; */
}

.thirty-days-stats .value .amount {
    font-size: 44px;
    line-height: 44px;
    text-align: center;
    color: #1e1053;
}

.thirty-days-stats .value .percentage-difference {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: center;
    font-size: 16px;
    text-align: center;
    color: rgba(229, 100, 62, 1);
}

.donor-dash-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}

.donor-dash-statistics .stats-container {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.donor-dash-statistics .stats-container .value {
    /* border: 1px solid green; */
    flex: 1.3;
    font-size: 16px;
    font-weight: bold;
}

.donor-dash-statistics .stats-container .title {
    /* border: 1px solid blue; */
    color: rgba(0, 0, 0, 0.8);
    flex: 3.7;
    font-weight: bold;
    font-size: 11.5px;
}

.update-comment {
    background: rgba(30, 16, 83, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    padding: 10px;
}

.update-comment .header {
    color: #e5643e;
    font-weight: bold;
    margin-bottom: 5px;
}

.update-comment .title {
    font-weight: bold;
    color: #000000;
}

.update-comment .desc {
    font-size: 90%;
}

/* Added By Joffin */

.profileImageUploadContainer {
    display: flex;
}

.profileImageUploadContainer .profileImageUploader {
    flex: 0.5;
}

.profileImageUploadContainer .profileImageUploadButton {
    flex: 2;
    margin: auto;
    padding-bottom: 10px;
    font-size: 12px;
}

.profileImageUploadContainer .profileImageUploadButton button {
    width: 220px;
}

.profileImageUploadContainer .profileImage {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: rgb(221, 221, 221) 5px double;
    margin-bottom: 10px;
    cursor: pointer;
}

/* orgLogoID */

#orgLogoID .orgLogo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: rgb(221, 221, 221) 5px double;
    cursor: pointer;
}

#orgLogoID .orgLogoUploader {
    position: relative;
}

#orgLogoID .uploadButton {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(218, 76, 118, 1);
    border: 3px white outset;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
}

#orgLogoID .spinner {
    animation: spin 3s linear infinite;
    font-size: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    0% {
        transform: rotateZ(0);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

/* End Of orgLogoID */

/* OrgProfileId */

#OrgProfileId .invert-button {
    border: 2px solid rgba(218, 76, 118, 1);
    background-color: white;
    color: rgba(218, 76, 118, 1);
    border-radius: 20px;
    font-weight: bold;
    padding: 3px 15px;
}

#OrgProfileId .pink-button {
    border: 2px solid rgba(218, 76, 118, 1);
    background-color: rgba(218, 76, 118, 1);
    color: white;
    border-radius: 20px;
    font-weight: bold;
    padding: 3px 15px;
}

#OrgProfileId .org-name-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#OrgProfileId .org-name-section .company-name {
    font-weight: bold;
    margin-left: 20px;
}

#OrgProfileId .org-name-section .company-type {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 5px;
}

#OrgProfileId .profileImage {
    margin-bottom: 0px;
}

#OrgProfileId .sub-box {
    margin-top: 30px;
}

#OrgProfileId .sub-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}

#OrgProfileId .sub-section .first-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#OrgProfileId .sub-section .title {
    /* font-size: 18px; */
    font-weight: bold;
}

#OrgProfileId .sub-section .field-name {
    color: rgba(0, 0, 0, 0.5);
}

#OrgProfileId .sub-section .field-value {
    font-weight: bold;
    margin-top: 10px;
    /* font-size: 15px; */
}

#OrgProfileId .stats {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#OrgProfileId .stats .value-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#OrgProfileId .stats .value-container .value {
    font-size: 36px;
    color: rgba(30, 16, 83, 1);
}

#OrgProfileId .stats .value-container .name {
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
}

#OrgProfileId .button-grouper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#OrgProfileId .button-grouper .change-password-button {
    width: 250px;
    margin-right: 20px;
    /* border-radius: 30px; */
}

.vertical-line {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
}

/* End Of OrgProfileId */

/* getFundstatistics */

#getFundstatistics.statistics {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

#getFundstatistics .element .value {
    font-size: 28px;
    color: rgba(30, 16, 83, 1);
    text-align: center;
}

#getFundstatistics .element .title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
}

/* End Of getFundStatistics */

/* getFundsUpdates */

#getFundsUpdates .first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
}

#getFundsUpdates .updates-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 250px;
    border-radius: 8px;
}

#getFundsUpdates .update {
    display: flex;
    flex-direction: column;
    background-color: rgba(30, 16, 83, 0.05);
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
}

#getFundsUpdates .update:hover {
    background-color: rgba(30, 16, 83, 0.1);
}

#getFundsUpdates .update .comment-first-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#getFundsUpdates .update .comment-first-row .comment-title {
    font-size: 12px;
    color: rgba(229, 100, 62, 1);
    /* font-weight: bold; */
}

#getFundsUpdates .update .comment-first-row .time-ago {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: normal;
}

#getFundsUpdates .update .campaign-title {
    margin-top: 5px;
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
    font-weight: bold;
}

#getFundsUpdates .update .comment {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    word-wrap: break-word;
}

#getFundsUpdates ::-webkit-scrollbar {
    width: 20px;
}

#getFundsUpdates ::-webkit-scrollbar-track {
    background-color: transparent;
}

#getFundsUpdates ::-webkit-scrollbar-thumb {
    background-color: rgba(30, 16, 83, 0.1);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

#getFundsUpdates ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(30, 16, 83, 0.2);
}

/*End of getFundsUpdates */

/* Start Of Monthly Donations */

#MonthlyDonations.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* End of Monthly Donations */

/*OngoingCampaigns */

#ongoingCampaigns .campaign {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

#ongoingCampaigns .first-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#ongoingCampaigns .content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}

#ongoingCampaigns .first-row .campaign-name {
    font-size: 14px;
    color: rgba(30, 16, 83, 1);
    font-weight: bold;
}

#ongoingCampaigns .first-row .cause {
    color: rgba(229, 100, 62, 1);
    margin-left: 20px;
}

#ongoingCampaigns .amount-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0.4;
}

#ongoingCampaigns .element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#ongoingCampaigns .element .value {
    color: rgba(68, 68, 68, 1);
    font-size: 14px;
    font-weight: bold;
}

#ongoingCampaigns .element .title {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    text-align: center;
}

#ongoingCampaigns .element .value-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

#ongoingCampaigns .element .perc-diff {
    font-size: 10px;
    font-weight: bold;
    margin-left: 10px;
}

#ongoingCampaigns .days-left {
    display: flex;
    flex: 0.1;
    justify-content: center;
}

#ongoingCampaigns .raised-this-month {
    display: flex;
    flex: 0.2;
    justify-content: center;
}

#ongoingCampaigns .campaign-progress {
    border: 1px solid black;
}

#ongoingCampaigns .percentage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#ongoingCampaigns .progress-bar {
    margin: 0px;
}

#ongoingCampaigns .perc-value {
    margin-left: 10px;
    font-size: 12px;
    font-size: bold;
    color: rgba(0, 0, 0, 0.8);
}

/*End Of OngoingCampaigns */

/* otherFundraisers */

#otherFundraisers .subtitle-row {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: rgba(30, 16, 83, 1);
    align-items: center;
}

#otherFundraisers .subtitle-row .subtitle {
    flex: 1;
}

/* #otherFundraisers .campaign-list {
} */

#otherFundraisers .campaign-list .campaign {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    align-items: center;
}

#otherFundraisers .campaign .element {
    flex: 1;
}

/* End Of Other Fundraiser */

/* donationsReceived */

#donationsReceived .subtitle-row {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: rgba(30, 16, 83, 1);
    align-items: center;
}

#donationsReceived .subtitle-row .subtitle {
    flex: 1;
}

/* #donationsReceived .donation-list {
} */

#donationsReceived .donation-list .donation {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
    align-items: center;
}

#donationsReceived .donation .element {
    flex: 1;
}

#getFundsId .get-funds-tab {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* border: 1px solid black; */
    align-items: flex-end;
    border-bottom: 1px solid rgba(226, 226, 226, 1);
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

#getFundsId .tab {
    padding: 10px 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#getFundsId .tab:hover {
    background: rgba(30, 16, 83, 0.05);
}

#getFundsId .tab-selected {
    flex: 1;
    text-align: center;
    margin-bottom: 0px;
    color: black;
    display: flex;
    flex-direction: column;
    position: relative;
}

#getFundsId .tab-selected .bottom-border {
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 0;
    background: linear-gradient(
        90deg,
        #f19400 2.95%,
        #e5643e 49.24%,
        #da3976 97.5%
    );
}

#getFundsId .other-tab {
    flex: 1;
    text-align: center;
    color: rgba(30, 16, 83, 1);
}

#dashboardLanding .title-row {
    font-size: 20px;
    color: rgba(30, 16, 83, 1);
    margin-top: 30px;
    margin-bottom: 10px;
}

#dashboardLanding .box-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#dashboardLanding .box-container .box-modifier {
}

#dashboardLanding .donor-dash-statistics {
    border-top: 1px solid rgba(226, 226, 226, 1);
    padding-top: 10px;
}

#dashboardLanding .box-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
}

#dashboardLanding .stats-container {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#dashboardLanding .stats-container .value {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

#dashboardLanding .stats-container .title {
    /* border: 1px solid blue; */
    color: rgba(0, 0, 0, 0.8);
    flex: 2;
    font-weight: bold;
    font-size: 11.5px;
}

/* Start of Profile style */

#profile-area .done-button {
    text-align: right;
    margin-top: 20px;
}
/* End of Profile style */

#getFundsId .draft {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media (min-width: 320px) and (max-width: 481px) {
    .dash-profile .name {
        font-size: 16px;
    }

    #getFundstatistics .element .value {
        font-size: 20px;
    }

    #getFundstatistics .element .title {
        font-size: 10px;
    }

    #getFundsId .draft {
        border-radius: 10px;
        padding: 10px 20px;
        box-shadow: inset 0 0 7px #ccc;
        justify-content: space-around;
        margin-bottom: 10px;
    }
    #getFundsId .draft .head {
        border-right: none;
    }

    #getFundsId .draft .firstline {
        flex-direction: column;
        text-align: center;
    }

    #getFundsId .draft .firstline h6 {
        font-size: 14px;
    }

    #getFundsId .draft .firstline .cause {
        margin-top: 5px;
        font-size: 12px;
    }

    #getFundsId .draft .meta {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    #getFundsId .draft .meta div {
        font-size: 11px;
        border-right: none;
        padding: 0px;
    }
    #getFundsId .draft .edit {
        justify-content: center;
        margin-top: 10px;
    }

    #ongoingCampaigns .campaign {
        border-radius: 10px;
        padding: 10px 20px;
        box-shadow: inset 0 0 7px #ccc;
        margin-bottom: 10px;
    }

    #ongoingCampaigns .first-row {
        flex-direction: column;
    }

    #ongoingCampaigns .content-row {
        flex-direction: column;
    }

    #donationsReceived .subtitle-row {
        display: none;
    }

    #donationsReceived .donation-list .donation {
        border: none;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: inset 0 0 7px #ccc;
        margin-bottom: 10px;
    }

    #otherFundraisers .subtitle-row {
        display: none;
    }

    #otherFundraisers .campaign-list .campaign {
        flex-direction: column;
        align-items: center;
        border-bottom: none;
        border-radius: 10px;
        margin-bottom: 10px;
        box-shadow: inset 0 0 7px #ccc;
    }

    #profile-area .done-button {
        text-align: center;
        margin-bottom: 20px;
    }

    .mobile-dropdown {
        max-height: 550px;
        overflow-y: scroll;
    }
}

@media (min-width: 641px) and (max-width: 1025px) {
    .dashboard-container {
        flex-direction: column;
    }

    #profile-area {
        font-size: 12px;
    }

    #profile-area .change-password {
        font-size: 12px;
    }

    #getFundsId .draft .firstline h6 {
        font-size: 12px;
    }

    #getFundsId .draft .firstline .cause {
        font-size: 10px;
    }

    #getFundsId .draft .meta div {
        font-size: 11px;
    }

    #ongoingCampaigns .content-row .title {
        font-size: 10px;
    }

    #donationsReceived .subtitle-row {
        font-size: 10px;
    }

    #donationsReceived .donation-list .donation {
        font-size: 11px;
    }

    #otherFundraisers .subtitle-row {
        font-size: 10px;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .dashboard-container {
        flex-direction: column;
    }

    .dashboard-menu {
        border-radius: 0px;
        flex: auto;
        padding: 5px !important;
        height: 80px;
        max-height: 80px;
    }

    .dashboard-menu.expanded {
        height: auto;
        max-height: fit-content;
    }

    .dash-menu-header {
        cursor: pointer;
    }

    .dash-menu-header .name i {
        padding-left: 20px !important;
        cursor: pointer;
    }
    .dash-menu-header .name span {
        color: #da3976;
    }

    .dash-profile .img img {
        max-width: 67px;
    }
    .dash-profile .img {
        max-width: 67px;
    }

    .dash-profile {
        margin-bottom: 0px !important;
    }

    .dash-menu-header {
        height: 100%;
    }

    .dash-menu-container > ul li.active {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    .dash-menu-container > ul li {
        padding-left: 5px !important;
    }

    .dash-menu-container > ul {
        padding-left: 0px !important;
    }

    .dashboard-content .box-modifier {
        width: 100% !important;
    }

    .thirty-days-stats .title {
        text-align: center;
    }
    #dashboardLanding .title-row {
        font-size: 20px !important;
        color: rgba(30, 16, 83, 1);
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        text-align: center;
    }
    .dashboard-content {
        margin-bottom: 10vh;
    }
    .fundraiser-cta {
        flex-direction: column;
        justify-content: center;
        margin: 10px 10px;
        max-width: 90vw;
    }
    .fundraiser-cta .right {
        padding-top: 20px;
    }
    .fundraiser-cta .right div {
        max-width: 80%;
        margin: auto;
        text-align: center;
    }

    .dash-content {
        max-width: 100vw;
    }

    #getFundsId .tab {
        font-size: 12px;
    }
    .dashboard-content.mobile-dash {
        padding: 0px;
    }
}
