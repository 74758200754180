#succesStoriesLists.need-help-list-main-container {
    width: 100%;
}
#succesStoriesLists .main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
#succesStoriesLists .main-success-stories-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    margin: 1rem 0px;
    color: #1e1053;
}
#succesStoriesLists .image-text {
    font-size: 0.7rem;
    word-spacing: 1.5px;
    line-height: 140%;
    display: flex;
}
#succesStoriesLists .np-padding {
    padding-left: 0px !important;
}
.row-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 20px;
    /* identical to box height */

    text-transform: capitalize;

    color: #1e1053;
}
.margin-top {
    margin-top: 1rem !important ;
}
.success-stories-list-image {
    height: 100px;
    margin-right: 10px;
    object-fit: contain;
    background-color: #ffffe0;
}
#succesStoriesLists .select-dropdown {
    height: fit-content;
    width: fit-content;
    border: none;
    background-color: transparent;
    padding: 0px 15px 0px 0px !important;
    padding-left: 0rem !important;
    cursor: pointer;
}
#succesStoriesLists .banner-edit-button {
    display: flex;
    height: fit-content;
    color: #da4c76;
    font-size: 0.8rem;
    cursor: pointer;
}
