#addPartner .main-add-partner-container {
    width: 100%;
}
#addPartner .main-add-partner-container {
    width: 100%;
}
#addPartner .main-banners-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* identical to box height */
    margin: 1rem 0px;
    color: #1e1053;
}
#addPartner .volunteer-details-input-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 0px 1rem 0px;
}
#addPartner .volunteer-bio {
    width: 100%;
    height: 10rem;
    border: 1px solid #ced4da;
    margin-bottom: 1rem;
}
#addPartner .short-bio-container {
    margin: 0.5rem 1rem;
    width: 100%;
}
#addPartner .margin-top {
    margin-top: 2rem !important;
}
#addPartner .short-bio-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 150%;

    color: #000000;
}
#addPartner .short-bio-maximum-characters {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.4rem;
    text-align: right;
    align-self: flex-end;
    color: #999999;
}
#addPartner .short-bio-heading-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
#addPartner .required-star {
    color: red;
    padding-left: 10px;
    vertical-align: middle;
    font-size: 12px;
    align-self: flex-start;
}
#addPartner .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 10rem;
}
#addPartner .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}
