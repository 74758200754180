#successStoryId .form-title {
    color: #1e1053;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.04em;
    margin-left: 15px;
    margin-bottom: 20px;
}

#successStoryId .buttons-section {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
#successStoryId .buttons-section .cancel-button {
    cursor: pointer;
    border: 1px solid #da4c76;
    border-radius: 100px;
    padding: 6px 40px;
    margin: 0px 20px;
}

/* Start of Success Story List style */

#successStoryListId .title {
    font-size: 20px;
    font-weight: 600;
    background-color: #f4f3f6;
    padding: 10px;
    margin-bottom: 20px;
}

#successStoryListId .list-heading {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

#successStoryListId .data-listing-section {
    min-height: 500px;
}

#successStoryListId .list-data {
    font-size: 14px;
    padding: 5px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
}
#successStoryListId .list-data:last-child {
    border-bottom: none;
}
#successStoryListId .card-image {
    width: 100%;
    height: 3rem;
    padding: 0px;
}
/* End of Success Story List style */
