
.availablity input::placeholder {
    font-size: 14px; 
  
}


/* input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
} */




/* @media (max-width: 767px) {
    input {
        font-size: 14px;
        padding: 8px;
    }
} */

