#needHelp.need-help--main-container {
    width: 100%;
}
#needHelp .mian-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin: 1rem;
    color: #1e1053;
    margin-bottom: 2rem;
}
#needHelp .margin-top {
    margin-top: 3rem !important;
}
#needHelp .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}
#needHelp .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}
#needHelp .date-container {
    padding: 15px;
}
#needHelp .date-picker {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.date-picker .react-datetime-picker__wrapper {
    border: none;
}
#needHelp .date-heading {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    padding-bottom: 0.5rem;
}
.highlighted-text-required {
    color: red;
    padding-left: 5px;
    vertical-align: middle;
    font-size: 12px;
}
