#materialsManagerDeashBoard.materials-manager-main-container {
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 100px;
}
#materialsManagerDeashBoard .materails-statistics-card-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2rem 0px;
}
#materialsManagerDeashBoard .card-container {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 20rem;
    padding: 0.8rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
}
#materialsManagerDeashBoard .card-heading-container,
.card-heading-container-design {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}
#materialsManagerDeashBoard .card-heading-container-design {
    background: rgba(30, 16, 83, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-bottom: 0.3rem;
}
#materialsManagerDeashBoard .card-heading,
.card-heading-design {
    font-size: 0.9rem;
    font-weight: 600;
}
#materialsManagerDeashBoard .card-heading-design {
    margin: 0px 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    color: #e5643e;
    font-weight: 400 !important;
    padding: 0.8rem 0px;
    font-size: 0.8rem;
}
#materialsManagerDeashBoard .main-heading {
    margin-bottom: 1rem;
}
#materialsManagerDeashBoard .card-heading-materials {
    max-height: 20rem;
    overflow-y: scroll;
    margin-top: 1rem;
    padding-right: 1rem;
}
#materialsManagerDeashBoard .active-text,
.active-text-design {
    text-align: center;
    width: 5rem;
}
#materialsManagerDeashBoard .active-text-design {
    background: rgba(30, 16, 83, 0.2);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 0.7rem;
    font-weight: 600;
    color: #1e1053;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0.5rem;
}
/* #materialsManagerDeashBoard ::-webkit-scrollbar-thumb {
    background-color: rgba(30, 16, 83, 0.1);
    border-radius: 20px;
    border: 20px solid transparent;
    background-clip: content-box;
}
#materialsManagerDeashBoard ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(30, 16, 83, 0.2);
    border-radius: 10px;
} */
#materialsManagerDeashBoard ::-webkit-scrollbar {
    width: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e5643e;
    border-radius: 10px;
    margin: 1rem;
}
#materialsManagerDeashBoard ::-webkit-scrollbar-track {
    background: rgba(196, 196, 196, 0.8);
    border-radius: 10px;
    margin: 0px 1rem;
}
#materialsManagerDeashBoard ::-webkit-scrollbar-thumb {
    background: #f0f0f0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
#materialsManagerDeashBoard .materials-statistics-container {
    width: 100%;
    padding: 1rem;
    border: 1px solid;
}
#materialsManagerDeashBoard .materials-statistics-container-heading {
    font-size: 1rem;
    font-weight: 600;
}
#materialsManagerDeashBoard .main-materials-progress-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#materialsManagerDeashBoard .material-progress-container {
    padding: 15px 40px;
    text-align: center;
    border-radius: 0.2rem;
    background: rgba(157, 33, 100, 0.12);
    position: relative;
    margin: 3rem 0rem;
}
#materialsManagerDeashBoard .campaign-progress {
    width: 40px;
    height: 40px;
    position: absolute;
    top: -2.3rem;
    left: 35%;
}
#materialsManagerDeashBoard .material-progress-image {
    width: 25px;
    height: 25px;
    align-self: center;
}
#materialsManagerDeashBoard .material-progress-number {
    font-size: 0.9rem;
}

#materialsManagerDeashBoard .material-progress-text {
    font-size: 0.7rem;
}
.no-padding {
    padding: 0px !important;
}
#materialsManagerDeashBoard .banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}
#materialsManagerDeashBoard .save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}
