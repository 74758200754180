.bs-input-wrapper {
    position: relative;
    width: 100%;
}

.date-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.date-input-wrapper input {
    flex: 1;
    height: 35px;
    border: 1px solid #ced4da;
    border-radius: 5px;
}

.calendar-icon {
    margin-left: -30px; /* Adjust as necessary */
}

.form-group {
    margin-bottom: 20px;
}

.reqfield {
    color: red;
}

customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    .customDatePickerWidth
    > div
    > div.react-datepicker__input-container
    input {
    width: 100%;
}
