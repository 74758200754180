#donationRequestsLists.main-container {
    height: 100%;
    min-height: 98%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

#donationRequestsLists .donor-requests-main-container {
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    white-space: nowrap;
}

#donationRequestsLists .heading-container {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #1e1053;
    margin: 1rem 0;
    padding: 0;
}

.padding-no {
    padding: 0 !important;
}

#donationRequestsLists .col-md-2 a {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#donationRequestsLists .organistaion-name {
    text-align: left !important;
}

.donor-requests-lists-container {
    width: 100%;
}

#donationRequestsLists .donor-requests-lists-contents {
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    margin-bottom: 1rem;
    padding: 0 !important;
    overflow-x: auto;
}

.save-button,
.cancel-button {
    background-color: #da4c76;
    border-radius: 3rem;
    padding: 0.5rem 2rem;
    color: white;
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 2rem;
    cursor: pointer;
}

.select-dropdown {
    height: fit-content;
    font-size: 0.7rem;
    width: fit-content;
    border: none;
    background-color: transparent;
    /* margin-left: -30px; */
    padding: 0;
    padding-left: 0 !important;
    cursor: pointer;
}

.banner-edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #da4c76;
    font-size: 0.8rem;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.banners-buttons {
    width: 100%;
    display: flex;
    text-transform: uppercase;
    margin-top: 3rem;
    align-self: flex-end;
}

.row-heading {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

.table-header,
.donor-requests-lists-container {
    display: flex;
    align-items: center;
    width: max-content;
}

.status-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.status-dropdown {
    cursor: pointer;
    user-select: none;
    position: relative;
}

.dropdown-icon {
    margin-left: 5px;
    transition: transform 0.3s ease;
}

.dropdown-icon.rotate {
    transform: rotate(180deg);
}

.status-options {
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    list-style: none;
    padding: 5px 0;
    margin: 0;
    z-index: 1000;
    width: 150px;
    top: 100%;
    left: 0;
}

.status-options li {
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.status-options li:hover {
    background-color: #f1f1f1;
}

/* .materials-requests-lists-container {
    width: 100%;
    display: flex;
    flex-direction: column;
} */

.horizontal-scroll {
    overflow-x: auto;
    white-space: nowrap;
}

.scrollable-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 1000px;
}

.scrollable-table th,
.scrollable-table td {
    padding: 8px 12px;
    text-align: left;
    border: 1px solid #ddd;
}

.scrollable-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.donation-table th,
.donation-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: nowrap;
}

.donation-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 0.8rem;
    color: #1e1053;
}

.donation-table td {
    font-size: 0.8rem;
}

.edit-icon {
    font-size: 16px;
    cursor: pointer;
    color: #da4c76;
}

.edit-icon:hover {
    color: #ff3366;
}

@media screen and (max-width: 768px) {
    .donation-table th,
    .donation-table td {
        padding: 8px;
    }

    .select-dropdown {
        font-size: 0.7rem;
    }

    .edit-icon {
        font-size: 14px;
    }
}
