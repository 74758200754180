.heading-main-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-right: 1rem;
}
.heading-one {
    font-size: 1.5rem;
    line-height: 180%;
    font-weight: 600;
    color: #1e1053;
}
.heading-component-back-button {
    color: #da4c76;
    display: flex;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
}
.heading-two {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 1.6rem;
    display: flex;
    text-transform: uppercase;
    color: #000000;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 1.5rem;
    padding: 0rem 1.5rem;
    cursor: pointer;
}
.heading-two-container {
    display: flex;
}
.left-margin {
    margin-left: 20px;
}
